exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-council-index-en-js": () => import("./../../../src/pages/council/index.en.js" /* webpackChunkName: "component---src-pages-council-index-en-js" */),
  "component---src-pages-council-index-hy-am-js": () => import("./../../../src/pages/council/index.hy-AM.js" /* webpackChunkName: "component---src-pages-council-index-hy-am-js" */),
  "component---src-pages-council-index-ru-js": () => import("./../../../src/pages/council/index.ru.js" /* webpackChunkName: "component---src-pages-council-index-ru-js" */),
  "component---src-pages-home-index-en-js": () => import("./../../../src/pages/home/index.en.js" /* webpackChunkName: "component---src-pages-home-index-en-js" */),
  "component---src-pages-home-index-hy-am-js": () => import("./../../../src/pages/home/index.hy-AM.js" /* webpackChunkName: "component---src-pages-home-index-hy-am-js" */),
  "component---src-pages-home-index-ru-js": () => import("./../../../src/pages/home/index.ru.js" /* webpackChunkName: "component---src-pages-home-index-ru-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-index-en-js": () => import("./../../../src/pages/members/index.en.js" /* webpackChunkName: "component---src-pages-members-index-en-js" */),
  "component---src-pages-members-index-hy-am-js": () => import("./../../../src/pages/members/index.hy-AM.js" /* webpackChunkName: "component---src-pages-members-index-hy-am-js" */),
  "component---src-pages-members-index-ru-js": () => import("./../../../src/pages/members/index.ru.js" /* webpackChunkName: "component---src-pages-members-index-ru-js" */),
  "component---src-pages-news-index-en-js": () => import("./../../../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-news-index-hy-am-js": () => import("./../../../src/pages/news/index.hy-AM.js" /* webpackChunkName: "component---src-pages-news-index-hy-am-js" */),
  "component---src-pages-news-index-ru-js": () => import("./../../../src/pages/news/index.ru.js" /* webpackChunkName: "component---src-pages-news-index-ru-js" */),
  "component---src-pages-partners-index-en-js": () => import("./../../../src/pages/partners/index.en.js" /* webpackChunkName: "component---src-pages-partners-index-en-js" */),
  "component---src-pages-partners-index-hy-am-js": () => import("./../../../src/pages/partners/index.hy-AM.js" /* webpackChunkName: "component---src-pages-partners-index-hy-am-js" */),
  "component---src-pages-partners-index-ru-js": () => import("./../../../src/pages/partners/index.ru.js" /* webpackChunkName: "component---src-pages-partners-index-ru-js" */),
  "component---src-pages-usefull-links-index-en-js": () => import("./../../../src/pages/usefullLinks/index.en.js" /* webpackChunkName: "component---src-pages-usefull-links-index-en-js" */),
  "component---src-pages-usefull-links-index-hy-am-js": () => import("./../../../src/pages/usefullLinks/index.hy-AM.js" /* webpackChunkName: "component---src-pages-usefull-links-index-hy-am-js" */),
  "component---src-pages-usefull-links-index-ru-js": () => import("./../../../src/pages/usefullLinks/index.ru.js" /* webpackChunkName: "component---src-pages-usefull-links-index-ru-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/NewsArticle.js" /* webpackChunkName: "component---src-templates-news-article-js" */)
}

